export default function IconPhone(): JSX.Element {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 10 10" id="Telephone-Phone--Streamline-Micro">
      <desc>{'Telephone Phone Streamline Icon: https://streamlinehq.com'}</desc>
      <path
        stroke="currentColor"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M3.783 9.151a2.215 2.215 0 0 1 -2.758 -0.3l-0.31 -0.31a0.738 0.738 0 0 1 0 -1.043l1.3 -1.3a0.739 0.739 0 0 1 1.044 0 0.738 0.738 0 0 0 1.043 0l2.091 -2.09a0.738 0.738 0 0 0 0 -1.044v0a0.739 0.739 0 0 1 0 -1.044L7.5 0.716a0.738 0.738 0 0 1 1.043 0l0.31 0.31a2.214 2.214 0 0 1 0.3 2.758 19.976 19.976 0 0 1 -5.369 5.367v0Z"
        strokeWidth={1}
      />
    </svg>
  );
}
